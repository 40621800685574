
export default [
    {
        title: {
            pt_br: "Acomodações",
            en_us: "Accommodations",
            es_es: "Alojamientos"
        },
        description: {
            pt_br: "Nossos apartamentos possuem uma estrutura completa para garantir seu conforto, com TV, camas box, ar condicionado e ou ventiladores, frigobar, telefone e secadores de cabelo à disposição quando necessário",
            en_us: "Our apartments have a complete structure to guarantee your comfort, with TV, box beds, air conditioning and/or fans, minibar, telephone and hair dryers available when necessary",
            es_es: "Nuestros departamentos cuentan con una estructura completa para garantizar su comodidad, con TV, camas box, aire acondicionado y/o ventiladores, minibar, teléfono y secadores de cabello disponibles cuando sea necesario"
        },
        icon: "fas fa-bed"
    }, {
        title: {
            pt_br: "Restaurante",
            en_us: "Restaurant",
            es_es: "Restaurante"
        },
        description: {
            pt_br: "Temos um cafe da manhã maravilhoso, entre os melhores da cidade. Completo e com um delicioso espaço interno e externo",
            en_us: "We have a wonderful breakfast, among the best in town. Complete and with a delicious internal and external space",
            es_es: "Tenemos un maravilloso desayuno, entre los mejores de la ciudad. Completo y con un delicioso espacio interno y externo"
        },
        icon: "fas fa-utensils"
    }, {
        title: {
            pt_br: "Salões de Eventos",
            en_us: "Event Halls",
            es_es: "Salones de Eventos"
        },
        description: {
            pt_br: "Contamos com um auditório espaçoso, para eventos formais, cerimónias e festas de finais de ano empresarial e muito mais",
            en_us: "We have a spacious auditorium for formal events, ceremonies and corporate year-end parties and much more",
            es_es: "Contamos con un amplio auditorio para eventos formales, ceremonias y fiestas corporativas de fin de año y mucho más"
        },
        icon: "fas fa-glass-cheers"
    }, {
        title: {
            pt_br: "Salas de Reuniões",
            en_us: "Meeting Rooms",
            es_es: "Salas de Reuniones"
        },
        description: {
            pt_br: "O hotel conta com salas para pequenas reuniões e eventos",
            en_us: "The hotel has rooms for small meetings and events",
            es_es: "El hotel dispone de salones para pequeñas reuniones y eventos"
        },
        icon: "fas fa-handshake"
    }, {
        title: {
            pt_br: "Atendimento 24 horas",
            en_us: "24 hour service",
            es_es: "Servicio 24 horas"
        },
        description: {
            pt_br: "Atendimento disponível a qualquer hora",
            en_us: "Service available at any time",
            es_es: "Servicio disponible en cualquier momento"
        },
        icon: "fas fa-clock"
    }, /*{
        title: {
            pt_br: "Academia",
            en_us: "Academy",
            es_es: "Academia"
        },
        description: {
            pt_br: "Temos disponível no hotel uma pequena academia, para manter seu ritmo de exercícios durante sua viagem",
            en_us: "We have a small gym available at the hotel, to keep your exercise rhythm during your trip",
            es_es: "Contamos con un pequeño gimnasio disponible en el hotel, para mantener tu ritmo de ejercicio durante tu viaje"
        },
        icon: "fas fa-dumbbell"
    },*/ {
        title: {
            pt_br: "Estacionamento Coberto",
            en_us: "Covered Parking",
            es_es: "Aparcamiento cubierto"
        },
        description: {
            pt_br: "Seu veículo também possui espaço seguro durante sua estadia",
            en_us: "Your vehicle also has safe space during your stay",
            es_es: "Su vehículo también cuenta con espacio seguro durante su estadía"
        },
        icon: "fas fa-car"
    }, {
        title: {
            pt_br: "Wi-Fi",
            en_us: "Wi-Fi",
            es_es: "Wi-Fi"
        },
        description: {
            pt_br: "Disponibilizámos Wi-Fi nas dependências do hotel, durante toda sua hospedagem",
            en_us: "We have Wi-Fi available on the hotel's premises throughout your stay",
            es_es: "Tenemos Wi-Fi disponible en las instalaciones del hotel durante toda su estancia"
        },
        icon: "fas fa-wifi"
    }
];